.link-popup {
	left: auto;
	right: 5px;
}

.rdw-emoji-modal {
	right: auto;
	left: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

#universe-container {
	flex-grow: 1;
	display: flex;
	position: relative;
}

#universe {
	z-index: 1;
	position: absolute;
	/* overflow: hidden; */
	width: 100%;
	height: 100%;
}

#galaxy {
	position: relative;
	width: 100%;
	height: 100%;
	transform: rotateX(75deg);
	transform-style: preserve-3d;
}

#sun,
.planet {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 1em;
	height: 1em;
	margin-top: -0.5em;
	margin-left: -0.5em;
	/*   border-radius: 50%; */
	transform-style: preserve-3d;
}

#sun {
	/*   background-color: #FB7209; */
	background-repeat: no-repeat;
	background-size: cover;
	/*   box-shadow: 0 0 60px rgba(255, 160, 60, 0.4);  */
	transform: rotateX(-75deg);
	width: 50%;
}

.pos {
	position: absolute;
	transform-style: preserve-3d;
	animation-name: invert;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.planet {
	/*   background-color: #202020; */
	background-repeat: no-repeat;
	background-size: cover;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.orbita {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 4px solid orange;
	border-radius: 50%;
	animation-name: orbit1;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform-style: preserve-3d;
}

.orbitb {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 4px solid orange;
	border-radius: 50%;
	animation-name: orbit2;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform-style: preserve-3d;
}

.orbitc {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 4px solid orange;
	border-radius: 50%;
	animation-name: orbit3;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform-style: preserve-3d;
}

/* Animations */
@keyframes orbit1 {
	0% {
		transform: rotateY(45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateY(45deg) rotateZ(-360deg);
	}
}

@keyframes orbit2 {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(-360deg);
	}
}

@keyframes orbit3 {
	0% {
		transform: rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateY(-45deg) rotateZ(-360deg);
	}
}

@keyframes invert {
	0% {
		transform: rotateX(-90deg) rotateY(360deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
	}
}

/* Orbit sizes */
#mercury.orbita {
	width: 100%;
	height: 100%;
	margin-top: -50%;
	margin-left: -50%;
}

#earth.orbitb {
	width: 100%;
	height: 100%;
	margin-top: -50%;
	margin-left: -50%;
}

#pluto.orbitc {
	width: 100%;
	height: 100%;
	margin-top: -50%;
	margin-left: -50%;
}

/* Planet starting positions */
#mercury .pos,
#earth .pos,
#pluto .pos {
	left: 50%;
	top: -1px;
	/*   border: solid 3px red; */
}

/* Planet animation durations */
#mercury.orbita,
#mercury .pos {
	animation-duration: 3s;
}

#earth.orbitb,
#earth .pos {
	animation-duration: 3.5s;
}

#pluto.orbitc,
#pluto .pos {
	animation-duration: 4.5s;
}

/* Planet sizes */
#sun {
	font-size: 18em;
}
#mercury,
#earth,
#pluto {
	font-size: 4em;
}

/* images */

#sun {
	background-image: url(https://firebasestorage.googleapis.com/v0/b/mujaz-42d66.appspot.com/o/phone-mujaz-2.png?alt=media&token=5c0d3d11-d6c7-4eec-a360-da6ef0bc0c64);
}

.planet {
	background-image: url(https://firebasestorage.googleapis.com/v0/b/mujaz-42d66.appspot.com/o/Moujaz-emblum.png?alt=media&token=73295b87-dc0c-4b9f-950f-4c24bab5c4ba);
}

.custom-shape-divider-bottom-1640712031 {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	line-height: 0;
	transform: rotate(180deg);
}

.custom-shape-divider-bottom-1640712031 svg {
	position: relative;
	display: block;
	width: calc(180% + 1.3px);
	height: 261px;
	transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1640712031 .shape-fill {
	fill: #efefef;
}
